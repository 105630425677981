import { render, staticRenderFns } from "./OpenVideo.vue?vue&type=template&id=110e8f1c&scoped=true&"
import script from "./OpenVideo.vue?vue&type=script&lang=js&"
export * from "./OpenVideo.vue?vue&type=script&lang=js&"
import style0 from "./OpenVideo.vue?vue&type=style&index=0&id=110e8f1c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110e8f1c",
  null
  
)

export default component.exports