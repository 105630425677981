<template>
    <div class="open-video">
        <div class="left">
            <div class="bg-box">
                <div class="one"></div>
                <div class="two"></div>
            </div>
            <div class="content">
                <div class="title-box">
                    <div>在线视频</div>
                    <div>插件新功能</div>
                </div>
                <div class="tips-box">
                    <div class="tips">
                        <img src="/assets/images/video/open-video-icon.png">
                        <span>全年10T视频流量，课程再多也不怕</span>
                    </div>
                    <div class="tips">
                        <img src="/assets/images/video/open-video-icon.png">
                        <span>300G储存空间，足够90%的用户使用</span>
                    </div>
                    <div class="tips">
                        <img src="/assets/images/video/open-video-icon.png">
                        <span>独立平台，知识付费变现更简单</span>
                    </div>
                </div>

                <div class="line"></div>

                <div v-if="status" class="pending">已完成支付，请等待管理员为您开通权限...</div>

                <div class="btn-box" v-else>
                    <div class="price">
                        <span>¥</span>
                        <span>3980.00</span>
                        <span>/年</span>
                    </div>
                    <div class="open-btn cursor" @click="open">立即开通</div>
                </div>
            </div>
        </div>
        <div class="right">
            <img src="/assets/images/video/open-video-detail.png">
        </div>
        <div class="overlay" v-if="show">

            <div class="pay-box">
                <div class="label">付款码</div>
                <div class="code-box">
                    <div class="line"></div>
                    <div class="tips">扫一扫，向颜创时代科技付款</div>
                    <div class="code">
                        <img :src="codeUrl">
                    </div>
                    <div class="num">扫码支付{{ price }}</div>
                </div>
                <div class="close" @click="show = false">
                    <img src="/assets/images/video/open-video-close.png">
                </div>

                <div class="done-box" v-if="status">
                    <div class="content">
                        <img class="icon" src="/assets/images/video/open-video-pay-done.png">
                        <div class="done-tips">
                            <div>已完成支付，请等待管理员</div>
                            <div>为您开通权限</div>
                        </div>
                    </div>
                    <div class="close" @click="show = false">
                        <img src="/assets/images/video/open-video-close.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/repo/api';
export default {
    name: "OpenVideo",

    data() {
        return {
            timer: 0,
            hideMenu: true,
            show: false,
            codeUrl: '',
            price: 0,
            status: 0,
            orderNo: 0,
        }
    },
    mounted() {
        this.getStatus();
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        open() {
            if (this.codeUrl) {
                this.show = true;
            } else {
                this.$loading.show()
                api.post('/ast-space/create-video-order', (res) => {
                    if (res.code == 0) {
                        this.price = res.data.price;
                        this.orderNo = res.data.no;
                        this.codeUrl = res.data.data_url;
                        this.show = true;
                        this.$loading.hide();
                        this.timer = setInterval(() => {
                            this.checkStatus();
                        }, 1200);
                    }
                })
            }
        },
        checkStatus() {
            api.get('/ast-space/video-order-status', (res) => {
                if (res.code == 0) {
                    this.status = res.data.is_paid;
                    if (res.data.is_paid) {
                        clearInterval(this.timer);
                    }
                }
            })
        },
        getStatus() {
            api.get('/ast-space/video-order-status', (res) => {
                if (res.code == 0) {
                    this.status = res.data.is_paid;
                }
            })
        }
    }
}

</script>

<style lang="less" scoped>
.overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;



    .pay-box {
        width: 384px;
        height: 541px;
        background: linear-gradient(180deg, #E5EFFF 0%, #F6F7FB 100%);
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 24px 40px;
        text-align: center;
        position: relative;

        .done-box {
            width: 384px;
            height: 541px;
            background: rgba(255, 255, 255, 0.9);
            text-align: center;
            backdrop-filter: blur(12px);
            position: absolute;
            top: 0;
            left: 0;
            .content{
                width: 304px;
                height: 399px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 74px auto 0;
            }
            .icon {
                width: 64px;
                height: 64px;
            }

            .done-tips {
                color: #333333;
                font-size: 16px;
                margin-top: 20px;
            }
        }

        .label {
            color: #074291;
            font-size: 24px;
            font-weight: bold;
            line-height: 34px;
        }

        .code-box {
            width: 304px;
            height: 399px;
            margin-top: 16px;
            background: url("/assets/images/video/open-video-bg2.png");
            background-size: cover;

            .line {
                width: 304px;
                height: 16px;
            }

            .tips {
                color: rgba(0, 0, 0, 0.5);
                font-size: 18px;
                line-height: 25px;
                margin: 20px auto;
            }

            .code {
                width: 240px;
                height: 240px;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .num {
                color: #333333;
                font-size: 24px;
                font-weight: bold;
                margin-top: 12px;
            }
        }
    }

    .close {
        width: 100%;
        height: fit-content;
        margin: 20px auto 0;

        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}

.open-video {
    width: 100%;
    height: 100%;
    display: flex;
}

.left {
    width: 524px;
    height: 300px;
    margin-top: 100px;
    position: relative;

    .bg-box {
        width: 100%;
        height: 100%;
        position: relative;

        div {
            border-radius: 110px;
            transform: rotate(45deg);
            background: linear-gradient(360deg, #F3F5FB 0%, #F8F9FB 100%);
            position: absolute;
        }

        .one {
            width: 198px;
            height: 120px;
            left: 16px;
            top: 49px;
        }

        .two {
            width: 180px;
            height: 100px;
            left: 200px;
            top: 164px;
        }
    }

    .content {
        width: 480px;
        position: absolute;
        top: 57px;
        left: 0;
        padding-left: 44px;
    }

    .title-box {
        display: flex;
        align-items: center;

        div {
            color: #333333;
            font-size: 24px;
            font-weight: bold;

            &:nth-child(1) {
                color: #003C89;
                margin-right: 12px;
            }
        }
    }

    .tips-box {
        margin-top: 40px;

        .tips {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            img {
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }

            span {
                color: #333333;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background: #EEEEEE;
        margin-top: 20px;
    }

    .pending {
        color: #003C89;
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
    }

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .price {
            span {
                color: #355FEB;
                font-size: 14px;
                font-weight: 500;

                &:nth-child(2) {
                    font-size: 24px;
                    margin-left: 4px;
                }
            }
        }

        .open-btn {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #FFFFFF;
            font-size: 14px;
            background: linear-gradient(360deg, #003C89 0%, #5B8FF9 100%);
        }
    }
}

.right {
    img {
        width: 792px;
        height: 540px;
    }
}
</style>